@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* This might save us a LOT of duplicated CSS */
@layer components {
  .toggle-bg:after {
    content: "";
    @apply absolute top-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
  }

  input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
  }

  input:checked + .toggle-bg {
    @apply bg-blue-300 border-blue-300;
  }

  /* Base overrides */
  html {
    @apply font-inter;
    -webkit-tap-highlight-color: transparent;
  }

  /* Form overrides */
  .responsive-form {
    @apply col-span-6 lg:col-span-6 xlg:col-span-6 lg:col-start-2 xlg:col-start-4 m-base lg:mx-0 lg:mt-xl px-base py-lg bg-white rounded;
  }
  label {
    @apply font-semibold;
  }
  select,
  input,
  [type="number"] {
    @apply px-base py-sm rounded-md bg-white;
  }
  [type="checkbox"],
  [type="radio"] {
    @apply px-0 py-0;
  }
  label.radio {
    @apply px-base py-sm rounded-md;
  }
  legend {
    @apply uppercase font-bold text-gray-800;
  }
  .select-mini select {
    @apply text-sm font-bold;
  }

  /* Navigation overrides */
  .popover-container {
    @apply rounded-lg shadow absolute top-2 right-10 p-4 mt-3 z-50 bg-white;
  }
  .popover-item {
    @apply text-gray-800 hover:bg-gray-300 text-base normal-case;
  }
  .dropdown__items-mobile {
    @apply xlg:rounded-xl xlg:shadow xlg:absolute mt-base xlg:p-md xlg:right-0 xlg:min-w-max;
  }
  .dropdown__item a,
  .dropdown__items a,
  .dropdown__items-mobile a {
    @apply p-sm text-sm uppercase;
  }

  /* Stripe overrides */
  .ElementsApp,
  .ElementsApp .InputElement {
    @apply text-base;
  }

  /* Table overrides */
  .th > .td {
    @apply text-xs;
  }

  /* Task dropdown items */
  .task-item {
    @apply flex items-center no-underline px-2 py-4 text-gray-800 bg-white hover:bg-blue-light-50 whitespace-nowrap;
  }

  .basis-full {
    flex-basis: 100%;
  }

  /* Button style component for enquiry calendar Call date buttons and 'Am', 'Pm' buttons style */
  .calendar-button {
    @apply label-checked:bg-blue-200 label-checked:text-blue-400 label-checked:border-blue-200 border border-current rounded py-3 px-10 mb-2 text-sm text-center text-gray-400;
  }
}

@responsive {
  @media (min-width: 52.125em) {
    .lg\:basis-none {
      flex-basis: 0px;
    }

    .lg\:flex-1 {
      flex: 1 1 0%;
    }

    .lg\:col-wide {
      flex-grow: 2.5;
    }

    .lg\:col-small {
      flex-grow: 1.2;
    }

    .lg\:col-medium {
      flex-grow: 1.5;
    }

    .lg\:pl-05em {
      padding-left: 0.5em;
    }

    .lg\:pr-05em {
      padding-right: 0.5em;
    }

    .lg\:pb-05em {
      padding-bottom: 0.5em;
    }
  }
}
